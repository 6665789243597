import { useEffect, useState } from 'react';

export const useClosestToThreshold = (nearestLocation, allWpJackpotType) => {
  const [sideJackpots, setSideJackpots] = useState([]);
  const [highLightedJackpot, setHighLightedJackpot] = useState('');

  useEffect(() => {
    if (!nearestLocation.id) return;
    //Filter jackpots based on location
    const locationJackpots = allWpJackpotType.nodes
      .map((locationJackpot) => ({
        threshold: locationJackpot.jackpotTypesMeta,
        jackpots: locationJackpot.jackpots.nodes.filter(
          ({ jackpotMeta: { location } }) => {
            return location.id === nearestLocation.id;
          },
        ),
      }))
      .filter((allLocationJackpots) => allLocationJackpots.jackpots.length);

    //Calculates biggest percentage for each threshold
    const thresholdPercentages = locationJackpots.map((jackpots) => {
      const thresholdMax = jackpots.threshold.maxAmount;
      const thresholdMin = jackpots.threshold.minAmount;

      if (jackpots.jackpots.length === 1) {
        return {
          percentage:
            (100 *
              (jackpots.jackpots[0].jackpotMeta.maxAmount - thresholdMin)) /
            (thresholdMax - thresholdMin),
          maxAmount: jackpots.jackpots[0].jackpotMeta.maxAmount,
          minAmount: jackpots.jackpots[0].jackpotMeta.minAmount,
        };
      }
      return jackpots.jackpots.reduce((acc, curr) => {
        const prevValuePercentage =
          (100 * (acc.jackpotMeta.maxAmount - thresholdMin)) /
          (thresholdMax - thresholdMin);
        const currentValuePercentage =
          (100 * (curr.jackpotMeta.maxAmount - thresholdMin)) /
          (thresholdMax - thresholdMin);

        return prevValuePercentage > currentValuePercentage
          ? {
              percentage: prevValuePercentage,
              maxAmount: acc.jackpotMeta.maxAmount,
              minAmount: acc.jackpotMeta.minAmount,
              id: acc.id,
            }
          : {
              percentage: currentValuePercentage,
              maxAmount: curr.jackpotMeta.maxAmount,
              minAmount: acc.jackpotMeta.minAmount,
              id: curr.id,
            };
      });
    });
    //Calculates closest to 100% percentage
    const closestThreshold =
      thresholdPercentages.length === 1
        ? thresholdPercentages[0]
        : thresholdPercentages.reduce((acc, curr) =>
            acc.percentage > curr.percentage ? acc : curr,
          );
    setHighLightedJackpot(closestThreshold);

    const allJackpots = [];
    locationJackpots.forEach((jackpot) => {
      allJackpots.push(...jackpot.jackpots);
    });

    const restJackpots = allJackpots
      .filter((jackpot) => jackpot.id !== closestThreshold.id)
      .slice(0, 4);
    setSideJackpots(restJackpots);
  }, [allWpJackpotType.nodes, nearestLocation.id]);

  return { sideJackpots, highLightedJackpot };
};
