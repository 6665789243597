import React, { useRef } from 'react';
import Button from '@components/atoms/Buttons/Button';
import BasicJackpotSection from '@components/organisms/BasicJackpotSection';
import { useInView } from 'framer-motion';
import styles from './BonusPageJackpotSection.module.scss';
import PropTypes from 'prop-types';
import SideJackpots from './SideJackpots';
import ShareButton from '@components/molecules/ShareButton/ShareButton';
import { useHandleHover } from '@hooks/useHandleHover';
import { scrollToElement } from '@helpers/scrollToSection';

const BonusPageJackpotSection = ({
  highLightedJackpot,
  restJackpots,
  content,
  locationLink,
  id,
  dataId,
  jackpotsSectionCurrency,
}) => {
  const ref = useRef(null);
  const { isHovered, handleMouseOut, handleMouseOver } = useHandleHover();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { once: true });

  return (
    <div id={id} className={`page-section ${styles.wrapper}`} data-id={dataId}>
      <div
        ref={containerRef}
        className={`${styles.infoWrapper} ${
          isInView ? 'in-view' : 'out-of-view'
        } transition-1000`}
      >
        <div className={styles.nameWrapper}>
          <p className="tag !ml-0">{content.name}</p>
          <div className="tag-line" />
        </div>
        <h2 className="h1">{content.title}</h2>
        <p className="md:text-center">{content.description}</p>
        <div className={styles.buttonWrapper}>
          <Button
            widthFull
            onClick={scrollToElement.bind(this, '#bonuses-section')}
            handleMouseOver={handleMouseOver}
            handleMouseOut={handleMouseOut}
          >
            {content.actionButton.title}
            <i
              className={`icon-interface-arrows-down ${styles.icon} ${
                isHovered ? 'top-1/2' : 'top-1/3'
              }`}
            />
          </Button>
        </div>
      </div>
      <div className={styles.jackpotsWrapper} ref={ref}>
        <BasicJackpotSection
          jackpotsSectionCurrency={jackpotsSectionCurrency}
          locationJackpot={{
            jackpotMeta: {
              maxAmount: highLightedJackpot.maxAmount,
              minAmount: highLightedJackpot.minAmount,
            },
          }}
          content={{
            text: content.jackpotsText,
            locationText: content.locationText,
          }}
          locationLink={locationLink}
        >
          <ShareButton hasSpacingTop />
        </BasicJackpotSection>

        <SideJackpots jackpots={restJackpots} ref={ref} />
      </div>
    </div>
  );
};

BonusPageJackpotSection.propTypes = {
  content: PropTypes.shape({
    actionButton: PropTypes.shape({
      title: PropTypes.string,
    }),
    description: PropTypes.string,
    jackpotsText: PropTypes.string,
    locationLink: PropTypes.string,
    locationText: PropTypes.string,
    name: PropTypes.string,
    text: PropTypes.string,
    title: PropTypes.string,
  }),
  dataId: PropTypes.number,
  highLightedJackpot: PropTypes.oneOfType([
    PropTypes.shape({
      maxAmount: PropTypes.number,
      minAmount: PropTypes.number,
    }),
    PropTypes.string,
  ]),
  id: PropTypes.string,
  jackpotsSectionCurrency: PropTypes.string,
  locationLink: PropTypes.string,
  restJackpots: PropTypes.any,
};

export default BonusPageJackpotSection;
