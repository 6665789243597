import React, { useContext, useEffect, useMemo, useState } from 'react';
import { graphql } from 'gatsby';
import SEO from '@components/molecules/SEO';
import Layout from '@layouts/Layout';
import HeroSection from '@components/organisms/HeroSection';
import SubscriptionForm from '@components/molecules/Forms/SubscriptionForm';
import useToggle from '@hooks/useToggle';
import SubImageDesktop from '@images/sub_form.png';
import SubImageMobile from '@images/sub_form_mobile.png';
import BonusSubImageDesktop from '@images/bonus_form_desktop.webp';
import BonusSubImageMobile from '@images/bonus_form_mobile.webp';
import HeroImage from '@images/bonus_page_hero.webp';
import HeroImageMobile from '@images/mobile_bonus_page_hero.webp';
import TopFixedSection from '@components/organisms/TopFixedSection';
import { contentSubscribeInputs } from '@helpers/constants';
import { transformObject } from '@helpers/transformObject';
import { normalizeLocations } from '@normalizers/normalizeLocations';
import ActiveCardsSection from '@components/organisms/ActiveCardsSection';
import BonusPageJackpotSection from '@components/organisms/BonusPageJackpotsSection';
import { useClosestToThreshold } from '@helpers/useClosestToThreshold';
import CalendarSection from '@components/organisms/CalendarSection';
import { getLocationsContent } from '@helpers/getLocationsContent';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import { SiteContext } from '@store/Provider';
import { motion } from 'framer-motion';
import GradientLine from '@components/molecules/GradientLine/GradientLine';

const BonusPage = (props) => {
  const {
    data: {
      allWp: {
        nodes
      },
      wpPage: page,
      allWpLocation,
      allWpBonus,
      allWpJackpotType,
      allWpEvent,
      wp: {
        siteSettings: {
          siteSettings: {
            jackpotsSectionCurrency,
            blogPostsActionButton,
            eventsSettingsAccordionTitle,
            registerFormErrorMessages,
            menuText,
            menuLogo,
            cookiesUsage,
            registerForm,
            registerFormPrivacyPolicyLink,
            registerFormPrivacyPolicyText,
            registerFormTermsAndConditionsText,
            registerFormTitle,
            termsAndConditions,
            ...subForm
          },
        },
      },
    },
  } = props;

  const {
    seo,
    uri,
    language,
    translations,
    template: { bonusesPageMeta },
  } = page;

  const currentLanguage = language ? language.slug : 'bg';

  const { toggled, handleToggleClick } = useToggle(false);
  const isMobile = useBreakpoint(breakpoints.md);

  const isMiddle = useBreakpoint(breakpoints.md);

  const subFormCheckboxes = transformObject(subForm.subFormCheckboxesLabels);

  const { nearestLocation } = useContext(SiteContext);

  const [activeBonuses, setActiveBonuses] = useState([]);

  const handleActiveBonusesEffect = () => {
    if (!nearestLocation.id || !allWpBonus) return;

    const content = allWpBonus.nodes.map((bonus) => ({
      id: bonus.databaseId,
      meta: bonus.bonusMeta,
      comparisonDate: bonus.bonusMeta.endDate,
    }));

    setActiveBonuses(getLocationsContent({ content, nearestLocation }));
  };
  useEffect(handleActiveBonusesEffect, [allWpBonus, nearestLocation]);

  const { sideJackpots, highLightedJackpot } = useClosestToThreshold(
    nearestLocation,
    allWpJackpotType,
  );

  const activeEvents = useMemo(() => {
    if (!nearestLocation.id || !allWpEvent) return [];

    const content = allWpEvent.nodes.map((event) => ({
      title: event.title,
      id: event.databaseId,
      meta: event.eventMeta,
      comparisonDate: event.eventMeta.startDate,
    }));

    return getLocationsContent({ content, nearestLocation });
  }, [allWpEvent, nearestLocation]);

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsRendered(true), 200);
  }, []);

  const [hideAdulthoodIcon, setHideAdulthoodIcon] = useState(false);

  return (
    <Layout
      translations={translations}
      currentLanguage={currentLanguage}
      sideMenuContent={bonusesPageMeta.bpiSideMenuSection.menuItems}
      setHideAdulthoodIcon={setHideAdulthoodIcon}
    >
      <SEO
        title={seo?.title}
        uri={uri}
        headJson={seo?.headJSON}
        imageToPreload={isMobile ? HeroImageMobile : HeroImage}
        translations={translations}
      />
      <TopFixedSection
        menuText={menuText}
        menuLogo={menuLogo}
        handleToggleClick={handleToggleClick}
        toggled={toggled}
        generalSettingsUrl={nodes[0].allSettings.generalSettingsUrl}
        {...(!hideAdulthoodIcon && { termsAndConditions: termsAndConditions })}
      />

      <HeroSection
        dataId={1}
        content={bonusesPageMeta.bpiHeroSection}
        heroImage={HeroImage}
        heroImageMobile={HeroImageMobile}
        scrollTo="#jackpot-section"
        rightContent={{
          icon:
            'icon-interface-alert-alarm-bell-1 md:!text-[45px] lg:!text-[50px]',
          text: bonusesPageMeta.bpiLeadToBonusesIconText.name,
          scrollTo: '#content-subscribe-form',
        }}
        toggled={toggled}
        handleToggleClick={handleToggleClick}
        animateHero={props.location.state?.animate}
        withShareButton
        Line={<GradientLine variant="bonus-one" />}
      />
      <div className="xmd:mt-[100px] lg:mt-[125px]">
        <BonusPageJackpotSection
          jackpotsSectionCurrency={jackpotsSectionCurrency}
          dataId={2}
          highLightedJackpot={highLightedJackpot}
          content={bonusesPageMeta.bpiJackpotsSection}
          locationLink={nearestLocation.url}
          restJackpots={sideJackpots}
          id="jackpot-section"
        />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        viewport={{ amount: 'some', once: true }}
      >
        <div className="relative">
          <ActiveCardsSection
            dataId={3}
            content={bonusesPageMeta.bpiBonusesSection}
            cards={activeBonuses}
            id="bonuses-section"
          />
          {!isMiddle && isRendered && (
            <GradientLine
              variant="bonus-two"
              position="absolute top-[45%] left-0 w-[900px] lg:w-[1000px]"
            />
          )}
        </div>
        <SubscriptionForm
          mailingList={
            subForm.subFormCheckboxesLabels.subFormCheckboxBonusesMailingList
          }
          isBonusSubscribe
          id="bonus-subscribe-form"
          title={registerFormTitle}
          errors={registerFormErrorMessages}
          privacy={{
            text: registerFormPrivacyPolicyText,
            link: registerFormPrivacyPolicyLink.url,
            linkText: registerFormPrivacyPolicyLink.title,
          }}
          buttonText={registerForm.button}
          inputs={[
            { label: registerForm.firstName, type: 'text', name: 'firstName' },
            { label: registerForm.lastName, type: 'text', name: 'lastName' },
            // Potentially usable
            // {
            //   label: registerForm.phoneNumber,
            //   type: 'text',
            //   name: 'phoneNumber',
            // },
            contentSubscribeInputs.find((input) => input.name === 'email'),
            { label: registerForm.location, type: 'select', name: 'select' },
          ]}
          list={normalizeLocations(allWpLocation)}
          imageDesktop={BonusSubImageDesktop}
          imageMobile={BonusSubImageMobile}
          checkboxText={registerFormTermsAndConditionsText}
          checkBoxType="checkbox"
        />
        <CalendarSection
          blogPostsActionButton={blogPostsActionButton}
          dataId={4}
          cards={activeEvents}
          content={bonusesPageMeta.bpiCalendarSection}
          buttonText={eventsSettingsAccordionTitle}
          id="events-section"
          withBiggerSpacing
          TopLine={
            <GradientLine
              variant="bonus-three"
              position="absolute top-[-147px] left-1/2"
            />
          }
          BottomLine={
            <GradientLine
              variant="bonus-four"
              position="absolute bottom-[-153px] left-1/2"
            />
          }
        />
        <SubscriptionForm
          id="content-subscribe-form"
          title={subForm.subFormTitle}
          description={subForm.subFormText}
          errors={registerFormErrorMessages}
          privacy={{
            text: subForm.subFormDisclaimer.text,
            link: subForm.subFormDisclaimer.link.url,
            linkText: subForm.subFormDisclaimer.link.title,
          }}
          isContentSubscribe
          buttonText={subForm.subFormButtonText}
          defaultChecked={['subFormCheckboxBonuses']}
          inputs={contentSubscribeInputs}
          subFormCheckboxes={subFormCheckboxes.array}
          imageDesktop={SubImageDesktop}
          imageMobile={SubImageMobile}
          checkBoxType="checkbox"
        />
      </motion.div>
    </Layout>
  );
};

export default BonusPage;

export const query = graphql`
  query BONUS_PAGE($id: String!) {
    wpPage(id: { eq: $id }) {
      ...BonusPageContent
    }
    allWp {
      nodes {
        allSettings {
          generalSettingsUrl
        }
      }
    }
    allWpBonus(sort: { fields: bonusMeta___endDate, order: ASC }) {
      nodes {
        bonusMeta {
          location {
            ... on WpLocation {
              id
              title
            }
          }
          description
          endDate
          imageType
          name
          startDate
        }
        databaseId
      }
    }
    wp {
      ...SubscriptionFormContent
      siteSettings {
        siteSettings {
          jackpotsSectionCurrency
          blogPostsActionButton
          eventsSettingsAccordionTitle
          registerFormErrorMessages {
            emptyField
            invalidCheckbox
            invalidField
            invalidPhone
            requiredField
          }
          cookiesUsage {
            text
            readMoreLink {
              title
              url
              target
            }
          }
          menuText
          menuLogo {
            altText
            mediaItemUrl
          }
          registerForm {
            button
            firstName
            lastName
            location
            phoneNumber
          }
          registerFormPrivacyPolicyLink {
            title
            url
            target
          }
          registerFormPrivacyPolicyText
          registerFormTermsAndConditionsText
          registerFormTitle
        }
      }
      ...CookiesAndTermsContent
    }
    allWpEvent(sort: { fields: eventMeta___startDate, order: ASC }) {
      nodes {
        title
        databaseId
        eventMeta {
          description
          name
          startDate
          location {
            ... on WpLocation {
              id
              title
              locationMeta {
                googleMapsLink {
                  url
                }
              }
            }
          }
        }
      }
    }
    allWpLocation {
      edges {
        node {
          locationMeta {
            latitude
            longitude
            name
            googleMapsLink {
              target
              title
              url
            }
          }
          id
        }
      }
    }
    allWpJackpotType {
      nodes {
        jackpotTypesMeta {
          maxAmount
          minAmount
        }
        jackpots {
          nodes {
            jackpotMeta {
              maxAmount
              minAmount
              location {
                ... on WpLocation {
                  id
                }
              }
            }
            id
          }
        }
      }
    }
  }
`;
