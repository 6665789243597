import React, { forwardRef } from 'react';
import { useUpdateDimensions } from '@hooks/useUpdateDimensions';

import { useInView } from 'framer-motion';
import styles from './BonusPageJackpotSection.module.scss';
import { landscapeBreakpoint } from '@helpers/constants';

const SideJackpots = forwardRef(({ jackpots }, ref) => {
  const { height } = useUpdateDimensions(ref);
  const isLandscapeDevice = height < landscapeBreakpoint;
  const inView = useInView(ref);
  return (
    <div>
      {!!jackpots.length &&
        jackpots.map((jackpot) => (
          <p
            key={jackpot.id}
            className={`${styles.jackpot} ${
              inView
                ? isLandscapeDevice
                  ? styles.jackpotInViewLandscape
                  : styles.jackpotInView
                : styles.jackpotNotInView
            }`}
          >
            {jackpot?.jackpotMeta?.maxAmount
              ?.toLocaleString()
              .replace(',', ' ')}
          </p>
        ))}
    </div>
  );
});

export default SideJackpots;
