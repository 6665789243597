import { useEffect, useState } from 'react';

const FIVE_SECONDS_INTERVALS_IN_DAY = 17280;

export const useBasicJackpotSection = (locationJackpot) => {
  const [amount, setAmount] = useState(null);
  const minAmount = locationJackpot?.jackpotMeta?.minAmount;
  const maxAmount = locationJackpot?.jackpotMeta?.maxAmount;
  const currentDate = new Date();
  const startOfDay = new Date();
  startOfDay.setHours(0, 0, 0, 0);

  const percentagePassed =
    ((currentDate.getTime() - startOfDay.getTime()) / (1000 * 60 * 60 * 24)) *
    100;

  const step = Math.ceil(
    (minAmount + (percentagePassed / 100) * (maxAmount - minAmount)) /
      FIVE_SECONDS_INTERVALS_IN_DAY,
  );

  const setInitialJackpotAmountEffect = () => {
    setAmount(
      Math.ceil(minAmount + (percentagePassed / 100) * (maxAmount - minAmount)),
    );
  };
  useEffect(setInitialJackpotAmountEffect, [maxAmount, minAmount]);

  const jackpotCounterEffect = () => {
    let interval;
    if (maxAmount < amount + step) {
      clearInterval(interval);
      setAmount(maxAmount);
      return;
    }
    interval = setInterval(() => {
      setAmount((prevCounter) =>
        prevCounter === maxAmount ? prevCounter : prevCounter + step,
      );
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  };
  useEffect(jackpotCounterEffect, [amount, maxAmount, step]);

  return {
    jackpotAmount: amount
      ? Math.round(amount)?.toLocaleString().replace(',', ' ')
      : '',
  };
};
