export const getLocationsContent = ({ content, nearestLocation }) => {
  const findClosestLocationBonus = content.filter(
    ({
      meta: {
        location: { id },
      },
    }) => id === nearestLocation.id,
  );

  const findDefaultLocationBonus = content.filter(
    ({
      meta: {
        location: { id },
      },
    }) => id !== nearestLocation.id,
  );

  const slicedLocationBonuses = [
    ...findClosestLocationBonus,
    ...findDefaultLocationBonus,
  ].slice(0, 16);

  return slicedLocationBonuses;
};
