import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { breakpoints } from '@helpers/constants';
import useBreakpoint from '@hooks/useBreakpoint';
import JackpotImage from '@images/jackpot_bg.webp';
import Spinner from '@components/atoms/Spinner/Spinner';
import { useHandleHover } from '@hooks/useHandleHover';
import { motion, useInView } from 'framer-motion';
import styles from './BasicJackpotSection.module.scss';
import { sectionTransitionDuration } from '@helpers/constants';
import { useBasicJackpotSection } from './useBasicJackpotSection';
import { useUpdateDimensions } from '@hooks/useUpdateDimensions';
import { landscapeBreakpoint } from '@helpers/constants';

const SCALE_INITIAL = 1;

const JackpotSection = ({
  locationJackpot,
  content,
  id,
  locationLink,
  isHomePage,
  children,
  jackpotsSectionCurrency,
}) => {
  const { isHovered, handleMouseOut, handleMouseOver } = useHandleHover();
  const isMobile = useBreakpoint(breakpoints.md);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const { jackpotAmount } = useBasicJackpotSection(locationJackpot);
  const { height } = useUpdateDimensions();
  return (
    <motion.div
      id={id}
      className={`${styles.wrapper} ${
        height < landscapeBreakpoint ? 'mt-[100px]' : 'mt-20'
      }`}
    >
      <img
        alt="jackpot-background"
        src={JackpotImage}
        className={`transition-all duration-[900ms] ${
          isInView ? (isMobile ? 'scale-[1]' : 'scale-[1.5]') : 'scale-[1]'
        }`}
      />
      <div
        ref={ref}
        className={`${styles.innerWrapper} ${isHomePage ? 'pb-37' : 'mt-80'}`}
      >
        {isHomePage && (
          <h2
            className={`${styles.title} ${
              isInView ? styles.outOfView : styles.inView
            } transition-1000`}
          >
            {content.text}
          </h2>
        )}
        {jackpotAmount ? (
          <>
            <motion.h2
              initial={{ scale: SCALE_INITIAL }}
              whileInView={{ scale: 1.1 }}
              transition={{ duration: sectionTransitionDuration }}
              data-attribute={jackpotAmount}
              className={styles.jackpot}
            >
              {jackpotAmount}
            </motion.h2>
            <p
              data-attribute={jackpotsSectionCurrency}
              className={styles.currency}
            >
              {jackpotsSectionCurrency}
            </p>
          </>
        ) : (
          <Spinner />
        )}

        {!isHomePage && (
          <h2
            className={`${styles.title} mb-[30px] ${
              isInView ? styles.outOfView : styles.inView
            } transition-1000`}
          >
            {content.text}
          </h2>
        )}
        <a
          target="_blank"
          href={locationLink}
          rel="noreferrer"
          className={`${styles.link} ${
            !isInView
              ? isMobile
                ? 'translate-x-[100%]'
                : 'translate-x-[400%]'
              : 'translate-x-0'
          } transition-1000`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
          onFocus={handleMouseOver}
          onBlur={handleMouseOut}
        >
          <span
            className={`${isHovered ? 'underline underline-offset-4' : ''}`}
          >
            {content.locationText}
          </span>
          <i className="icon-travel-map-location-pin" />
        </a>
        {children}
      </div>
    </motion.div>
  );
};

JackpotSection.propTypes = {
  children: PropTypes.node,
  content: PropTypes.shape({
    locationText: PropTypes.string,
    text: PropTypes.string,
  }),
  id: PropTypes.string,
  isHomePage: PropTypes.bool,
  jackpotsSectionCurrency: PropTypes.string,
  locationJackpot: PropTypes.object,
  locationLink: PropTypes.string,
};

export default JackpotSection;
